@import "src/styles/lib";

.Header {
  height: 60px;
  width: 100%;
  max-width: 1140px;
  padding: 0 50px 5px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    width: 100px;
    &:hover {
      cursor: pointer;
    }

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-label {
      font-size: 14px;
      margin-left: 7px;
      margin-top: 3px;
      color: #a8abb7;
    }
  }

  &__right {
    display: flex;
  }

  &__logOut {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $error;
    margin-left: 20px;
    background-color: #eef0f1;
    border-radius: 5px;
    padding: 10px 20px;

    &:hover {
      cursor: pointer;
      background-color: darken(#eef0f1, 5%);
      color: darken($error, 5%);
    }

    &-icon {
      width: 17px;
    }

    &-label {
      margin-left: 5px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

@include mobile {
  .Header {
    padding: 0 20px 5px 20px;
  }
}
