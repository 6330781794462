@import "src/styles/lib";

.PrivateRouteLayout {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 110px 50px 50px 50px;

  &__header {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid $border-two;
    border-bottom: 1px solid $border-two;

    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__body {
    width: 100%;
    max-width: 1140px;
  }
}

@include mobile {
  .PrivateRouteLayout {
    padding: 110px 20px 50px 20px;
  }
}
