@import "src/styles/lib";

.SearchInput {
  height: 44px;
  width: 400px;
  background-color: #eef0f1;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &__icon {
    width: 15px;
    height: 15px;
    color: #60687e;
    margin: 0 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #60687e;

    &::placeholder {
      color: lighten(#60687e, 25%);
    }
  }
}
