@import "../../styles/lib";

.PublicRouteLayout {
  width: 100%;
  height: 100vh;

  &__loader {
    width: 100%;
    height: 2%;
  }

  &__children {
    width: 100%;
    height: 98%;
  }
}
