@import "src/styles/lib";

.Accordion {
  background-color: $white;
  border: 1px solid $border-two;
  border-radius: 5px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__left {
    width: 100%;
    padding: 30px 0 30px 30px;
  }

  &__icon {
    width: 15px;
    background-color: white;
    padding: 5px;
    margin: 30px 30px 30px 0;

    &--isOpen {
      transform: rotate(180deg);
    }
  }

  &__title {
    color: $black;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__subtitle {
    color: #a8abb7;
    font-size: 14px;
  }

  &__body {
    padding: 30px 0;
    margin: 0 30px;
    border-top: 1px solid $border-two;
    animation-name: OpenEffect;
    animation-duration: 1s;
  }
}

@keyframes OpenEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include mobile {
  .Accordion {
    &__left {
      padding: 20px 0 20px 20px;
    }

    &__icon {
      margin: 20px 20px 20px 0;
    }

    &__body {
      padding: 20px 0;
      margin: 0 20px;
    }
  }
}
