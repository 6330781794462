@import "src/styles/lib";

.EmailForm {
  &__row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
  }

  &__input {
    &-label {
      font-size: 12px;
      margin-bottom: 10px;
    }

    &--errorMessage {
      font-size: 12px;
      text-align: right;
      padding: 5px;
      color: $error;
      line-height: 1em;
    }
  }

  &__button {
    &-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}

@include mobile {
  .EmailForm {
    &__row {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__button {
      width: 100%;
    }
  }
}
