@import "src/styles/lib";

.NotFound {
  height: 100%;
  width: 100%;
  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 82px;
    color: $error;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 40px;
  }

  &__subtitle {
    font-size: 32px;
    font-weight: bold;
    color: $black;
    margin-bottom: 30px;
  }
}
