@import "src/styles/lib";

.LoginForm {
  &__title {
    font-size: 20px;
    color: $black;
    font-weight: bold;
    text-align: center;
    margin-bottom: 18px;
  }

  &__paragraph {
    font-size: 16px;
    color: $slate-gray;
    text-align: center;
    margin: 0 30px 30px 30px;
  }

  &__input {
    margin: 20px 0 20px 0;

    &-label {
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  &__input2 {
    margin-bottom: 40px;
  }

  &__errorMessage {
    font-size: 12px;
    text-align: right;
    padding: 5px;
    color: $error;
    line-height: 1em;
    text-transform: capitalize;
  }
}
