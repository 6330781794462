@import "src/styles/lib";

.LoadingBar {
  width: 100%;
  height: 5px;
  background-color: transparent;

  &__progress {
    height: 100%;
    background-color: $robin-blue;
  }
}
