@import "src/styles/lib";

.EmailVerification {
  height: 100%;
  width: 100%;
  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
