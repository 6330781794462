@import "colors";
@import "fonts";

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  input,
  button,
  p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-family: $lato;
    font-size: 16px;
    color: $black;
  }
}
