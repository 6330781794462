@import "src/styles/lib";

.TopBar {
  width: 100%;
  background-color: $white;
  border: 1px solid $border-two;
  margin-bottom: 30px;

  &__profile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    &-picture {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      //object-fit: cover;

      &--container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-icon {
      width: 10px;
      color: $white;
      &--container {
        width: 21px;
        height: 21px;
        background-color: $robin-blue;
        border-radius: 50%;
        border: 2px solid $white;
        position: absolute;
        right: -6px;
        bottom: -2px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: darken($robin-blue, 10%);
        }
      }
    }

    &-container {
      margin-left: 20px;
    }

    &-name {
      color: $black;
      font-size: 14px;
      font-weight: bold;
    }
    &-email {
      font-size: 14px;
      color: #a8abb7;
    }
  }

  &__links {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $border-two;
  }

  &__link {
    font-size: 16px;
    margin: 0 20px;
    text-decoration: none;
    color: #60687e;
    text-align: center;

    &:hover > &-label {
      color: $robin-blue;
    }

    &-label {
      padding: 15px 0 10px 0;

      &--active {
        color: $robin-blue;
      }
    }

    &-border {
      width: 100%;
      height: 3px;
      border-radius: 2px 2px 0 0;
      background-color: transparent;

      &--active {
        background-color: $robin-blue;
      }
    }
  }
}

@include mobile {
  .TopBar {
    &__link {
      &-label {
        font-size: 14px;
      }
    }
  }
}
