@import "src/styles/lib";

.Button {
  position: relative;
  min-width: 150px;
  height: 48px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  padding: 10px 25px;
  line-height: 15px;
  letter-spacing: 0;
  font-family: $lato;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue;
  color: $white;

  &:hover {
    background: darken($blue, 10%);
  }

  &:hover > &__tooltip {
    visibility: visible;
    transition: 0.5s;
  }

  &__left--icon {
    margin-right: 9px;
    > svg {
      height: 12px;
      width: 12px;
    }
  }
  &__right--icon {
    margin-left: 9px;
    > svg {
      height: 12px;
      width: 12px;
    }
  }

  &__green {
    background: $green;
    color: $white;
    &:hover {
      background: darken($green, 10%);
    }
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }

  &__primary {
    background: $blue;
    color: $white;
    &:hover {
      background: darken($blue, 10%);
    }
    &--outline {
      background: transparent;
      border: 1px solid $blue;
      color: $blue;
      &:hover {
        background: $blue;
        color: $white;
      }
    }
  }

  &__info {
    background: $tiffany-blue;
    color: $white;
    &:hover {
      background: darken($tiffany-blue, 10%);
    }
    &--outline {
      background: transparent;
      border: 1px solid $tiffany-blue;
      color: $tiffany-blue;
      &:hover {
        background: $tiffany-blue;
        color: $white;
      }
    }
  }

  &__success {
    background: $green;
    color: $white;
    &:hover {
      background: darken($green, 10%);
    }
    &--outline {
      background: transparent;
      border: 1px solid $green;
      color: $green;
      &:hover {
        background: $green;
        color: $white;
      }
    }
  }

  &__dark {
    background: $yankee-blue;
    color: $white;
    &:hover {
      background: darken($yankee-blue, 10%);
    }
    &--outline {
      background: transparent;
      border: 1px solid $yankee-blue;
      color: $yankee-blue;
      &:hover {
        background: $yankee-blue;
        color: $white;
      }
    }
  }

  &__light {
    background: $robin-blue;
    color: $white;
    &:hover {
      background: darken($robin-blue, 10%);
    }
    &--outline {
      background: transparent;
      border: 1px solid $robin-blue;
      color: $robin-blue;
      &:hover {
        background: $robin-blue;
        color: $white;
      }
    }
  }
}
