$white: #ffffff;
$black: #000000;
$transparent: transparent;

$blue: #2185d0;
$green: #21ba45;
$robin-blue: #62b5e5;
$yankee-blue: #172a36;
$tiffany-blue: #00b5ad;
$slate-gray: #767c8e;
$cadet-blue: #b1b4c2;
$silver-sand: #c4c4c4;

$background-color: #fafafa;

$error: #e9635e;
$warning: #f5c24b;
$success: #7ad979;
$info: #4a84e3;

$border: #e8e8e8;

$border-two: #eaecef;
