@import "src/styles/lib";

.FileUpload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 400px;
  width: 500px;

  &__title {
    color: $black;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
  }
  &__subtitle {
    color: $black;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  &__body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 30px 0 20px 0;
    border: 1px solid $border-two;
    background-color: $background-color;
  }

  &__error {
    color: $error;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__input-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: repeating-linear-gradient(
        -45deg,
        rgba(97, 181, 228, 0.2),
        rgba(97, 181, 228, 0.2) 10px,
        $white 10px,
        $white 20px
      );

      background-size: 200% 200%;
      animation: barberpole 10s linear infinite;

      @keyframes barberpole {
        100% {
          background-position: 100% 100%;
        }
      }
    }
  }

  &__paragraph {
    color: $slate-gray;
    width: 250px;
  }

  &__image-container {
    background-color: $background-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__remove {
    visibility: hidden;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: 1px solid $white;
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 13px;
      padding: 2px 4px;

      &:hover {
        cursor: pointer;
      }
    }

    &-paragraph {
      color: $white;
      max-width: 200px;
      word-wrap: break-word;
    }
  }

  &__remove--show {
    visibility: visible;
  }

  &__footer {
    display: flex;
  }

  &__button {
    margin-right: rem(15);
  }
}
