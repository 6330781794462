@import "src/styles/lib";

.PopUp {
  position: relative;
  display: none;

  &--show {
    display: block;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    animation-name: PopUpShow;
    animation-duration: 1.5s;

    &--top {
      align-items: flex-start;
    }
    &--bottom {
      align-items: flex-end;
    }
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }

  &__container {
    min-width: 200px;
    background-color: $white;
    border: 1px solid $border-two;
    margin: 30px;
    animation-name: PopUpFadeIn;
    animation-duration: 1s;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0 0;

    &--icon {
      width: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}

@keyframes PopUpShow {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes PopUpFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
